export enum ChannelENUM {
  None = 0,
  Banner = 1,
  Video = 2,
  FacebookInstagram = 3,
  ConnectedTV = 4,
  Audio = 5,
  Email = 6,
  Search = 7,
  Twitter = 8,
  DataOnly = 9,
  Measurement = 10,
  LinkedIn = 11,
  ExternalDSP = 12,
  TikTok = 13,
  Snapchat = 14,
  XandrDMP = 15,
  Endemic = 16,
  TradeDeskDMP = 17,
  LiveRamp = 18,
  DirectSold = 19,
  EndemicDTC = 20,
  Reddit = 21,
  Yahoo = 22,
  Adtheorent = 23,
  Reserved = 24,
  EndemicEHR = 25,
  EndemicENL = 26,
  ProgrammaticGuaranteed = 28,
}
